// import React from "react";
import React from "react";

import imageStyles from "./images.module.css";
import Head from "../components/head";
import { graphql } from "gatsby";

export const query = graphql`
  query {
    allContentfulWebsiteLinks {
      edges {
        node {
          description {
            description
          }
          websiteLink {
            websiteLink
          }
        }
      }
    }
  }
`;

class WebsiteLinks extends React.Component {
  state = {};

  componentDidMount() {
    console.log(this.props.data.allContentfulWebsiteLinks.edges.length);
  }
  render() {
    return (
      <>
        <Head title="Website links"></Head>
        <div
          style={{ padding: "0 20px" }}
          className={imageStyles.imageContainer}
        >
          <h1>Website Links</h1>
          {this.props.data.allContentfulWebsiteLinks.edges.length === 1 &&
          this.props.data.allContentfulWebsiteLinks.edges[0].node.description
            .description === "null" ? (
              <h3 style={{textAlign:"center"}}>No links have been uploaded</h3>
          ) : (
            this.props.data.allContentfulWebsiteLinks.edges.map(
              (edge, index) => {
                if (edge.node.description.description !== "null") {
                  return (
                    <div key={index} style={{ padding: "20px 0" }}>
                      <p>{edge.node.description.description}</p>
                      <a
                        href={`${edge.node.websiteLink.websiteLink}`}
                        target="_blank"
                      >
                        {" "}
                        {edge.node.websiteLink.websiteLink}
                      </a>
                    </div>
                  );
                }
              }
            )
          )}
        </div>
      </>
    );
  }
}
export default WebsiteLinks;
